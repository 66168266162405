import * as React from "react"
import styled from "@emotion/styled"

const CenterMe = styled.div`
display: grid;
justify-content: center;
`
const Button = styled.button`
cursor: pointer;
font-family: Oswald;
background: var(--blue);
text-align: center;
border: none;
padding: 16px;

// centers the button
margin: 0 auto 32px auto;
display: inline-block;

a {
    color: white;
    text-decoration: none;
}
`

const StyledButton = ({ children }) => (
    <CenterMe>
        <Button>{children}</Button>
    </CenterMe>
)

export default StyledButton