import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import Seo from '../../components/seo'
import HeroImageA from '../../components/MainHero'
import StyledButton from '../../components/button'

const Gallery = () => {
  const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, max-content));
    justify-content: center;
    width: 100%;

    column-gap: 1em;
    row-gap: 2em;

    div {
      display: grid;
      justify-content: center;
      align-items: center;

      h2 {
        font-size: 2rem;
        padding-bottom: 16px;
      }
      button {
        margin-top: 8px;
      }
    }

    @media (min-width: 768px) {
      justify-content: space-evenly;
    }
  `
  // for staticImage start
  const width = 360
  const height = 360
  // for staticImage end

  return (
    <Layout>
      <Seo
        title="A gallery of our Welding projects"
        description="A small sample of our work"
      />

      {/* Hero Image is found in /components/MainHero.js */}
      <HeroImageA />

      <SiteWidthWrapper>
        <h1>Projects Gallery</h1>
        <p style={{ textAlign: `center`, marginBottom: `64px` }}>
          Below is a brief collection of our work. This gallery includes a few
          in-progress pieces, befores, and afters in no particular order.
        </p>

        <div style={{ width: `100%`, margin: `0 auto` }}>
          <GridWrapper>
            <div>
              <h2>Commercial Kitchens</h2>
              <StaticImage
                src="../../images/Commercial Kitchens/Counter-Top-2.jpg"
                alt="gallery image 1"
                objectFit="contain"
                width={width}
                height={height}
                layout="fixed"
                quality={95}
                placeholder="dominantColor"
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading="lazy"
              />
              <StyledButton>
                <Link
                  to="/gallery/commercial-kitchen-gallery/"
                  alt="Commercial Kitchen Gallery"
                >
                  Commercial Kitchens
                </Link>
              </StyledButton>
            </div>
            <div>
              <h2>Commercial Sinks</h2>
              <StaticImage
                src="../../images/Commercial Sinks/Bar-Sinks-8.jpg"
                alt="gallery image 2"
                objectFit="contain"
                width={width}
                height={height}
                layout="fixed"
                quality={95}
                placeholder="dominantColor"
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading="lazy"
              />
              <StyledButton>
                <Link
                  to="/gallery/commercial-sinks-gallery/"
                  alt="Custom Commercial Sinks gallery"
                >
                  Commercial Sinks
                </Link>
              </StyledButton>
            </div>
          </GridWrapper>
          <GridWrapper>
            <div>
              <h2>Commercial Breweries</h2>
              <StaticImage
                src="../../images/Commercial Breweries/Beer-Taps-3.jpg"
                alt="gallery image 4"
                objectFit="contain"
                width={width}
                height={height}
                layout="fixed"
                quality={95}
                placeholder="dominantColor"
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading="eager"
              />
              <StyledButton>
                <Link
                  to="/gallery/commercial-brewery-gallery/"
                  alt="Commercial Breweries Gallery"
                >
                  Commercial Breweries
                </Link>
              </StyledButton>
            </div>
            <div>
              <h2>Misc. Projects</h2>
              <StaticImage
                src="../../images/Misc Projects/Boat-2.jpg"
                alt="gallery image 4"
                objectFit="contain"
                width={width}
                height={height}
                layout="fixed"
                quality={95}
                placeholder="dominantColor"
                formats={['AUTO', 'WEBP', 'AVIF']}
                loading="lazy"
              />
              <StyledButton>
                <Link to="/gallery/misc-gallery/" alt="misc projects gallery">
                  Misc. Projects
                </Link>
              </StyledButton>
            </div>
          </GridWrapper>
        </div>
      </SiteWidthWrapper>
    </Layout>
  )
}

export default Gallery
